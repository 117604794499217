<template>
    <div class="call-to-action pt-120 pb-120 bgn-4 overflow-x-hidden" id="cta">
        <div class="container">
            <div class="row justify-content-between g-6">
                <div class="col-lg-6">
                    <span class="display-three tcn-1 cursor-scale growUp mb-8 d-block title-anim">Have any questions?</span>
                    <span class="fs-lg tcn-6">
                        Have questions or feedback? We'd love to hear from you. Reach out to our team or use our
                        contact
                        form.
                    </span>
                </div>
                <div class="col-xl-5 col-lg-6">

    <form @submit.prevent="submitForm">
      <div class="single-input mb-6">
        <input type="email" v-model="formData.email" required placeholder="Enter your email">
      </div>
      <div class="single-input mb-6">
        <input type="text" v-model="formData.feedback" required placeholder="Your feedback">
      </div>
      <div class="d-flex align-items-md-center align-items-start justify-content-between gap-lg-8 gap-6 flex-md-row flex-column">
        <div class="d-flex align-items-center gap-lg-4 gap-2">
          <label class="custom-checkbox">
            <input type="checkbox" v-model="formData.agree" required>
            <span class="checkmark"></span>
          </label>
          <span class="fs-base tcn-6">I agree with <a href="#" class="tcp-1">Privacy Policy</a> and <a href="terms-condition.html" class="tcp-1">Terms & Conditions</a></span>
        </div>
        <button type="submit" class="bttn py-sm-4 py-3 px-lg-10 px-sm-8 px-6 bgp-1 tcn-1 rounded-4">Submit</button>
      </div>
    </form>
</div>
</div>
</div>
</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        formData: {
          email: '',
          feedback: '',
          agree: false
        }
      };
    },
    methods: {
      async submitForm() {
        if (!this.formData.email || !this.formData.feedback || !this.formData.agree) {
          alert('Please fill in all required fields and agree to the terms.'); // You can replace this with more sophisticated error handling
          return;
        }
        try {
            alert("Thank you. We will get back to you soon.");
            return;
          /* const response = await fetch('/api/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.formData)
          });
          if (response.ok) {
            // Handle success (e.g., show a success message)
            console.log('Email sent successfully');
          } else {
            // Handle error (e.g., show an error message)
            console.error('Failed to send email');
          } */
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  };
  </script>
  