//router / inde.js

import { createRouter, createWebHistory } from 'vue-router';
import RegistrationView from '../components/RegistrationView.vue'; // Import your Registration component
import HomeView from '../components/HomeView.vue';
import ThankyouView from '@/components/thankyouView.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
      },
  {
    path: '/registration',
    name: 'Registration',
    component: RegistrationView
  },
  {
    path: '/thankyou/:fullname',
    name: 'ThankyouView',
    component: ThankyouView,
    props: true
  },
  // Other routes...
];

const router = createRouter({
  history: createWebHistory(), // Vue 3 uses createWebHistory
  routes
});

export default router;
