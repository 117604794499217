<template>
    <br /><br /><br />
    <div class="container mt-100 mb-100">
        <div class="row mb-5">
            <h2 style="color:rgb(251,204,4)">Welcome</h2>
        </div>
        <div class="row">
            <p class="msg">Congratulations, {{ fullname }}! You're now officially registered. Mark your calendars for the upcoming course launch - we're counting down the days! We'll be in touch via phone and PUBGM UID to keep you updated. Stay tuned!</p>
        </div>
        <div class="row">
      <CountdownTimer />
    </div>
</div>
<br />
  </template>
  
  <script>
  import CountdownTimer from '../components/CountdownTimer.vue'; // Import the CountdownTimer component
  
  export default {
    components: {
      CountdownTimer // Register the CountdownTimer component
    },
    props: {
    fullname: {
      type: String,
      required: true
    }
  },
  };
  </script>
  
 <style scoped>
.msg {
    font-size:22px;
    color:white;
}
</style>