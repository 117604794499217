<template>
    <div class="countdown-timer">
      <div class="timer">
        <div class="timer-item">
          <span>{{ days }}</span>
          <span>Days</span>
        </div>
        <div class="timer-item">
          <span>{{ hours }}</span>
          <span>Hours</span>
        </div>
        <div class="timer-item">
          <span>{{ minutes }}</span>
          <span>Minutes</span>
        </div>
        <div class="timer-item">
          <span>{{ seconds }}</span>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        targetDate: new Date('2024-06-06T00:00:00').getTime(), // Target date in milliseconds
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        timerInterval: null
      };
    },
    mounted() {
      // Update the countdown timer every second
      this.timerInterval = setInterval(this.updateTimer, 1000);
      // Initial call to update timer immediately
      this.updateTimer();
    },
    beforeUnmount() {
      // Clean up timerInterval to prevent memory leaks
      clearInterval(this.timerInterval);
    },
    methods: {
      updateTimer() {
        const currentTime = new Date().getTime();
        const timeDifference = this.targetDate - currentTime;
  
        if (timeDifference > 0) {
          this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        } else {
          // Countdown has reached the target date
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
          clearInterval(this.timerInterval); // Stop updating the timer
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 24px;
    font-weight: bold;
    color:rgb(251,204,4)
  }
  
  .timer {
    display: flex;
  }
  
  .timer-item {
    margin: 0 10px;
    text-align: center;
  }
  </style>
  