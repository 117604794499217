
<template>
    <!-- game section start  -->
<section class="game-section">
        <div class="container">
            <div class="row mb-5  d-flex justify-content-between">
                <h2 class="text-left" style="color:rgb(251,204,4)"><u>Benefits of Course</u></h2>
            </div>
           <!--  <div class="row mb-5  d-flex justify-content-between">
                <div class="col-sm-3 mb-2">
                    <img class="w-100 " src="assets/img/benefits/daraz.png" alt="game">
                </div>
                <div class="col-sm-3">
                    <img class="w-100 " src="assets/img/benefits/Certficate.png" alt="game">
                </div>
                <div class="col-sm-3">
                    <img class="w-100 " src="assets/img/benefits/merch.png" alt="game">
                </div>
            </div> -->
            <div class="row mb-5 d-flex justify-content-between">
                
                <div class="col-sm-6">
                    <img class="w-100 " src="assets/img/benefits/Merch 2.png" alt="game">
                </div>
                <div class="col-sm-6">
                    <img class="w-100 " src="assets/img/benefits/All.png" alt="game">
                </div>
            </div>
        </div>
    </section>
    <!-- game section end  -->
</template>

<script>

export default {
  name: 'GamewView',
 
}

</script>