<template>
  <div class="content-wrapper mt-100 mb-100">
    <div class="container">
      <div class="row">
        <h2 style="color: rgb(251, 204, 4)">Registration Form</h2>
        <div>
          <ul>
            <li>
              <div class="single-input">
                <input
                  placeholder="Full Name"
                  v-model="formData.fullname"
                  type="text"
                  id="fullname"
                />
              </div>
            </li>
            <li>
              <div class="single-input">
                <input
                  v-model="formData.pubgmId"
                  placeholder="Enter PUBGM ID"
                  type="number"
                  id="pubgmId"
                />
                <br /><span class="spanTxt"
                  >For receiving Creator Code and submitting WOW Maps</span
                >
              </div>
            </li>

            <li>
              <div class="single-input">
                <input
                  v-model="formData.email"
                  placeholder="Enter Email"
                  type="email"
                  id="email"
                />
                <br />
                <span class="spanTxt"
                  >Password will be sent on your email.</span
                >
              </div>
            </li>
            <li>
              <div class="single-input">
                <div class="mobilenum-wrapper">
                  <input
                    v-model="formData.mobileNum"
                    placeholder="Enter Mobile Number"
                    type="text"
                    id="mobileNum"
                  />
                  <span class="required-asterisk">*</span>
                </div>
                <span class="spanTxt"
                  >Password will be sent on your phone number</span
                >
              </div>
            </li>
            <li>
              <div class="single-input">
                <select
                  class="form-control"
                  v-model="formData.city"
                  name="usercity"
                  id="usercity"
                >
                  <option selected value="-1">Select Your City</option>
                  <option value="Peshawar">Peshawar</option>
                  <option value="Quetta">Quetta</option>
                  <option value="Islamabad">Islamabad</option>
                  <option value="Karachi">Karachi</option>
                  <option value="Lahore">Lahore</option>
                  <option value="Faisalabad">Faisalabad</option>
                  <option value="Rawalpindi">Rawalpindi</option>
                  <option value="Bhurban">Bhurban</option>
                  <option value="Muzaffarabad">Muzaffarabad</option>
                  <option value="Multan">Multan</option>
                  <option value="Shigar">Shigar</option>
                  <option value="Zamzama Gas Field">Zamzama Gas Field</option>
                  <option value="Dadu">Dadu</option>
                  <option value="Abbottabad">Abbottabad</option>
                  <option value="Bhagatanwala">Bhagatanwala</option>
                  <option value="Bannu">Bannu</option>
                  <option value="Bahawalnagar">Bahawalnagar</option>
                  <option value="Bahawalpur">Bahawalpur</option>
                  <option value="Chitral">Chitral</option>
                  <option value="Chilas">Chilas</option>
                  <option value="Dalbandin">Dalbandin</option>
                  <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
                  <option value="Dera Ismael Khan">Dera Ismael Khan</option>
                  <option value="Gwadar">Gwadar</option>
                  <option value="Gilgit">Gilgit</option>
                  <option value="Jacobabad">Jacobabad</option>
                  <option value="Jiwani">Jiwani</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Khuzdar">Khuzdar</option>
                  <option value="Kalat">Kalat</option>
                  <option value="Kohat">Kohat</option>
                  <option value="Loralai">Loralai</option>
                  <option value="Mangla">Mangla</option>
                  <option value="Mianwali">Mianwali</option>
                  <option value="Mohenjodaro">Mohenjodaro</option>
                  <option value="Mirpur Khas">Mirpur Khas</option>
                  <option value="Sindhri">Sindhri</option>
                  <option value="Kamra">Kamra</option>
                  <option value="Nawabshah">Nawabshah</option>
                  <option value="Nushki">Nushki</option>
                  <option value="Ormara Raik">Ormara Raik</option>
                  <option value="Parachinar">Parachinar</option>
                  <option value="Panjgur">Panjgur</option>
                  <option value="Pasni">Pasni</option>
                  <option value="Rahim Yar Khan">Rahim Yar Khan</option>
                  <option value="Rawalakot">Rawalakot</option>
                  <option value="Sibi">Sibi</option>
                  <option value="Skardu">Skardu</option>
                  <option value="Sehwan Sharif">Sehwan Sharif</option>
                  <option value="Sargodha">Sargodha</option>
                  <option value="Saidu Sharif">Saidu Sharif</option>
                  <option value="Sialkot">Sialkot</option>
                  <option value="Sui">Sui</option>
                  <option value="Sahiwal">Sahiwal</option>
                  <option value="Tarbela">Tarbela</option>
                  <option value="Badin">Badin</option>
                  <option value="Taftan">Taftan</option>
                  <option value="Turbat">Turbat</option>
                  <option value="Waana">Waana</option>
                  <option value="Fort Sandeman">Fort Sandeman</option>
                  <option value="Campbellpore">Campbellpore</option>
                  <option value="Gujrat">Gujrat</option>
                  <option value="Mansehra">Mansehra</option>
                  <option value="Kadanwari">Kadanwari</option>
                  <option value="Chagai">Chagai</option>
                  <option value="Sawan">Sawan</option>
                  <option value="Shikarpur">Shikarpur</option>
                  <option value="Chakwal">Chakwal</option>
                  <option value="Jehlum">Jehlum</option>
                </select>
              </div>
            </li>
            <li class="single-input">
            <button
                :disabled="isSubmitting"
                @click="debouncedSubmitForm"
                class="btn-half-border reg-now-btn position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill"
                style="background-color: rgb(251, 204, 4); border: 1px solid rgb(18 55 238);"
              >
                <span v-if="isSubmitting">Submitting...</span>
                <span v-else>Register</span>
              </button>
            </li>
          </ul>
        </div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }} <br /> <br /></div>
      </div>
    </div>
  </div>
  <br /><br /><br /><br />
</template>

<script>
import axios from "axios";
import debounce from "lodash/debounce";

export default {
  name: "RegistrationView",
  data() {
    return {
      formData: {
        fullname: "",
        email: "",
        pubgmId: "",
        mobileNum: "",
        city: "-1",
      },
      isSubmitting: false, // New state to track form submission
      errorMessage: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.isSubmitting) return;

      if (!this.validateForm()) return;

      this.isSubmitting = true; // Disable the submit button

      // Proceed with form submission to PHP API
      try {
        const response = await axios.post("http://gamenow.pubgmwow.pk/submitForm.php", {
          action: "register",
          fullname: this.formData.fullname,
          email: this.formData.email,
          pubgmId: this.formData.pubgmId.toString(),
          mobileNum: this.formData.mobileNum,
          city: this.formData.city,
          password: this.formData.pubgmId.toString(),
        }, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(response.data);
        this.$router.push({
          name: "ThankyouView",
          params: { fullname: this.formData.fullname },
        });
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.message;
        }
      } finally {
        this.isSubmitting = false; // Re-enable the submit button
      }
    },
    validateForm() {
      if (!this.formData.pubgmId) {
        alert("Please provide your PUBG Mobile ID.");
        return false;
      }

      if (!this.isValidEmail(this.formData.email)) {
        alert("Please enter a valid email address.");
        return false;
      }

      if (this.formData.fullname.length < 3) {
        alert("Name must be at least 3 characters long.");
        return false;
      }

      const phoneRegex = /^\+\d{1,3}\d{9,13}$/;
      if (!phoneRegex.test(this.formData.mobileNum)) {
        alert("Phone number must start with + followed by country code and be between 12 to 16 digits long in total.");
        return false;
      }

      if (this.formData.city === "-1") {
        alert("Please select your city.");
        return false;
      }

      return true;
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
  },
  created() {
    this.debouncedSubmitForm = debounce(this.submitForm, 300);
  },
};
</script>

<style scoped>
.single-input label {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align the label content to the left */
}

.mobilenum-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusted to create space between elements */
}

.mobilenum-wrapper input {
  flex-grow: 1; /* Make input take available space */
}

.mobilenum-wrapper .required-asterisk {
  color: red; /* Adjust the color of the asterisk if needed */
  margin-left: 5px; /* Add space between input and asterisk */
}

.single-input label span {
  margin-right: 5px; /* Adjust the space between the asterisk and input field */
  color: red; /* Adjust the color of the asterisk if needed */
}

/* Your existing styles */
.error-message {
  color: red;
  margin-top: 10px;
}
.reg-section {
  transform: translateY(-120%);
  transition: all 0.9s ease-in-out;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
  max-height: 1000px;
}

.container {
  max-height: 500px;
}

.reg-section.active {
  transform: translateY(0%);
}

.reg-section .reg-overlay {
  background: rgba(var(--n5), 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: -1;
}

.reg-section .wrapper {
  max-width: 600px; /* Adjust width as needed */
  margin: auto;
  position: relative; /* Ensure proper positioning of child elements */
  display: flex; /* Use flex to center children vertically and horizontally */
  align-items: center; /* Align children vertically */
  justify-content: center; /* Center children horizontally */
}

.reg-section .reg-close-btn {
  z-index: 1100; /* Ensure the close button appears above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.reg-section .area {
  border: 1px solid rgb(18 55 238);
  padding: 20px; /* Add padding to provide spacing inside the container */
  max-width: 600px; /* Max width to avoid overly wide elements on larger screens */
  max-height: 900px;
  width: 100%; /* Full width to utilize the space provided by the wrapper */
  position: relative; /* Ensure proper positioning of child elements */
  background-color: #000; /* Set a dark background for the form area */
  border-radius: 8px; /* Add rounded corners */
}

.reg-section .item {
  padding: 15px; /* More padding for better spacing */
  width: 100%; /* Ensure items take full width of the column */
}

.reg-section .single-input input,
.reg-section .single-input select {
  width: 100%; /* Make inputs extend full width of their container */
  padding: 10px; /* Increase padding for better visual appeal */
  background: #333; /* Dark background for input fields */
  color: #fff; /* Light text */
  border: none;
  border-radius: 5px; /* Rounded corners */
}

.reg-section .reg-now-btn {
  width: 100%; /* Full width button */
  padding: 12px 0; /* Taller button */
  border: 1px solid rgb(18 55 238);
  background-color: rgb(251, 204, 4); /* Set a distinct background color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.reg-section .reg-now-btn:hover {
  background-color: #0f2f8c; /* Darken the button on hover for better interaction feedback */
}

.reg-section .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.reg-section .area {
  padding: 20px;
  border: 1px solid rgb(18 55 238);
  max-width: 600px;
  background-color: #000;
  border-radius: 8px;
}

.reg-section .option {
  width: 100%;
}

.row {
  margin-bottom: 20px; /* Adds margin between rows */
}

.item {
  padding: 15px;
  background: #333;
  border-radius: 5px;
  display: flex; /* Ensures content aligns nicely */
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
}

.single-input input,
.single-input select {
  width: 100%;
  padding: 10px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.reg-now-btn {
  width: 100%;
  padding: 12px 0;
  background-color: #1237fe;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Ensuring equal height of columns in the last row */
.col-md-6 {
  display: flex;
  flex-direction: column;
}

.item {
  flex: 1; /* Makes each item fill the container */
}

.spanTxt {
  color: rgb(251, 204, 4);
  display: block;
  text-align: left;
  margin-top: 5px;
}
</style>
